<template>
  <router-view/>
</template>

<style>
@import '@/assets/css/main.css';
</style>

<script>
export default {
  name: 'App',
  data: () => {
    return {
    }
  },
}
</script>
